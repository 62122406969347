import { useEffect, useState } from "react";
import ChevronLeftIcon from "../../../assets/svg/ChevronLeftIcon";
import ProfileImg from "../../../assets/profilePic1.png";
import { useNavigate, useParams } from "react-router-dom";
import LocationIconOutline from "../../../assets/svg/LocationIconOutline";
import Email from "../../../assets/svg/Email";
import Phone from "../../../assets/svg/Phone";
import SmartPhone from "../../../assets/svg/SmartPhone";
import CopyIcon from "../../../assets/svg/CopyIcon";
import { AccountTrack } from "../dummydata";
import GroupIcon from "../../../assets/svg/GroupIcon";
import GreenCheckmark from "../../../assets/svg/GreenCheckmark";
import CrossIcon from "../../../assets/svg/CrossIcon";
import {
  approvalRequest,
  makeAdminById,
  recruiterDetailById,
  suspendAccount,
} from "../../../endpoints/recruiter.service";
import Spinner from "../../../components/Spinner/Spinner";
import { useSnackbar } from "notistack";
import LoadingAnimation from "../../../assets/svg/LoadingAnimation";
import { useForm } from "react-hook-form";
import { colors } from "../../../utils/theme";
import { recruiterApprovalStatus } from "../../../utils/constants";

const AccountMembersDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { watch, setValue } = useForm({
    defaultValues: {
      activeTab: "personal",
      detail: undefined,
      isLoading: false,
    },
  });
  const fields = watch();
  const [loading, setLoading] = useState({
    adminLoading: false,
    suspendLoading: false,
    deleteLoading: false,
    approvalLoading: false,
    rejectLoading: false,
  });

  const updateLoading = (value) => {
    setLoading((prev) => {
      return { ...prev, ...value };
    });
  };

  const fetchUserDetails = async () => {
    try {
      setValue("isLoading", true);

      const { data, success } = await recruiterDetailById({
        recruiterId: params?.id,
      });

      if (success) setValue("detail", data);
    } catch (error) {
    } finally {
      setValue("isLoading", false);
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    return `${formattedDate}, ${formattedTime}`;
  };
  const formattedDate = formatDate(fields?.detail?.registeredAt);

  const handleApproveRequest = async (status) => {
    updateLoading(
      status === 1 ? { approvalLoading: true } : { rejectLoading: true }
    );
    try {
      const data = await approvalRequest({
        recruiterId: params?.id,
        status: status,
      });

      if (data.success) {
        return enqueueSnackbar(data.data.message, {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
      console.log("Approval Error", error);
    } finally {
      updateLoading(
        status === 1 ? { approvalLoading: false } : { rejectLoading: false }
      );
    }
  };

  const handleSuspendAccount = async () => {
    updateLoading({ suspendLoading: true });
    try {
      const data = await suspendAccount({
        recruiterId: params?.id,
      });

      if (data.success) {
        return enqueueSnackbar(data.data.message, {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
      console.log("Suspend Account Error", error);
    } finally {
      updateLoading({ suspendLoading: false });
    }
  };

  const handleMakeAdmin = async () => {
    updateLoading({ adminLoading: true });
    try {
      const data = await makeAdminById({
        recruiterId: params?.id,
      });

      if (data.success) {
        return enqueueSnackbar(data.data.message, {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
      console.log("Make Admin Error", error);
    } finally {
      updateLoading({ adminLoading: false });
    }
  };

  const handleDeleteAccount = async () => {
    updateLoading({ deleteLoading: true });
    try {
      const data = await makeAdminById({
        recruiterId: params?.id,
      });

      if (data.success) {
        return enqueueSnackbar(data.data.message, {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
      console.log("Make Admin Error", error);
    } finally {
      updateLoading({ deleteLoading: false });
    }
  };

  const handleTabChange = (tab) => {
    setValue("activeTab", tab);
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  console.log(fields?.detail);

  return (
    <div className="flex flex-col overflow-y-hidden h-full rounded-lg bg-white w-full flex-grow p-4">
      <button
        onClick={() => navigate("/account/account-members", { replace: true })}
        className="flex items-center self-start p-5"
      >
        <ChevronLeftIcon className={"w-4 h-4"} />
        <label className="text-xs font-medium text-primary cursor-pointer">
          Back to listing
        </label>
      </button>

      <div className="flex gap-3">
        <button
          className={`text-sm rounded font-medium py-2 px-3 ${
            fields?.activeTab === "personal"
              ? "bg-[#DCEEFF] text-[#2E2E2E]"
              : "text-[#BABABA] border-[#DDDDDD] border"
          }`}
          onClick={() => handleTabChange("personal")}
        >
          Personal Details
        </button>
        <button
          className={`text-sm rounded font-medium py-2 px-3 ${
            fields?.activeTab === "tracker"
              ? "bg-[#DCEEFF] text-[#2E2E2E]"
              : "text-[#BABABA] border-[#DDDDDD] border"
          }`}
          onClick={() => handleTabChange("tracker")}
        >
          Tracker
        </button>
      </div>

      {fields?.isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <Spinner />
        </div>
      ) : (
        <div className="mt-5 overflow-y-scroll no-scrollbar h-full flex flex-1 flex-col">
          {fields?.activeTab === "personal" ? (
            <div className="flex ml-12 mt-16">
              <div className="bg-[#FAFAFA] p-4 flex gap-11 w-[500px] h-[380px] rounded">
                <div className="flex w-full gap-10">
                  <div className="flex flex-col justify-between">
                    <div className="text-center">
                      <img
                        src={ProfileImg}
                        alt="profile img"
                        className="h-32 w-h-32 bg-gray-500 rounded-full"
                      />
                      <div className="flex items-center gap-1 justify-center mt-2">
                        <p className="flex h-2 w-2 bg-green-500 rounded-full"></p>
                        <p className="text-[#6F6F6F] text-xs">online</p>
                      </div>
                    </div>
                    <div className="text-xs text-[#2E2E2E] font-semibold flex flex-col gap-2 text-right">
                      <p>Registered</p>
                      <p>Last logged in</p>
                    </div>
                  </div>
                  <div className="flex flex-col justify-between">
                    <div>
                      <p className="text-[#2E2E2E] font-semibold text-2xl">
                        {fields?.detail?.firstName} {fields?.detail?.lastName}
                      </p>
                      <p className="text-xs text-[#6F6F6F] font-semibold mt-4 mb-3">
                        {fields?.detail?.agentPosition || "Agent"}
                      </p>
                      <p className="text-xs text-[#FF9800] flex gap-2 items-center">
                        <span>
                          <LocationIconOutline
                            className={"h-5 text-[#6F6F6F]"}
                            fill={"#6F6F6F"}
                          />
                        </span>
                        United Kingdom
                      </p>
                    </div>
                    <div className="text-xs text-[#6F6F6F] font-semibold flex flex-col gap-3">
                      <p className="text-xs flex gap-3">
                        <span>
                          <Email />
                        </span>
                        {fields?.detail?.email}
                        <span>
                          <CopyIcon
                            className={"w-3.5 h-3.5 cursor-pointer"}
                            fill={""}
                          />
                        </span>
                      </p>
                      <p className="text-xs flex gap-3">
                        <span>
                          <Phone />
                        </span>
                        {fields?.detail?.phone || "5555555555"}
                      </p>
                      <p className="text-xs flex gap-3">
                        <span>
                          <SmartPhone />
                        </span>
                        {fields?.detail?.phone || "5555555555"}
                      </p>
                    </div>
                    <div className="flex flex-col gap-3">
                      <p className="text-[#2E2E2E] text-xs italic font">
                        {formattedDate}
                      </p>
                      <p className="text-[#2E2E2E] text-xs italic font">
                        {formattedDate}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-px bg-gray-400 mx-11"></div>
              <div className="flex flex-col gap-16">
                <div className="flex flex-col gap-5">
                  <button
                    className="flex items-center gap-4 text-xs font-medium bg-[#ECECEC] rounded px-8 py-2"
                    onClick={handleMakeAdmin}
                  >
                    Make an Admin
                    {loading?.adminLoading ? (
                      <LoadingAnimation color={"white"} />
                    ) : null}
                  </button>
                  <button
                    onClick={handleSuspendAccount}
                    className="flex items-center gap-4 text-xs font-medium bg-[#ECECEC] rounded px-8 py-2"
                  >
                    Suspend account
                    {loading?.suspendLoading ? (
                      <LoadingAnimation color={"white"} />
                    ) : null}
                  </button>
                  <button
                    // onClick={handle}
                    className="flex items-center gap-4 text-xs font-medium bg-[#ECECEC] rounded px-8 py-2"
                  >
                    Delete account
                    {loading?.deleteLoading ? (
                      <LoadingAnimation color={"white"} />
                    ) : null}
                  </button>
                </div>
                {(fields?.detail?.recruiterApprovalStatus ===
                  recruiterApprovalStatus.ALL ||
                  fields?.detail?.recruiterApprovalStatus ===
                    recruiterApprovalStatus.PENDING) && (
                  <div className="flex flex-col gap-5">
                    <p className="text-center text-sm font-bold underline">
                      Approval Pending
                    </p>
                    <button
                      className="text-xs border-[#23BD33] font-medium border text-[#23BD33] rounded px-8 py-2 flex gap-3"
                      onClick={() => handleApproveRequest(1)}
                    >
                      Approve account{" "}
                      {loading?.approvalLoading ? (
                        <LoadingAnimation color={"lightgray"} />
                      ) : (
                        <GreenCheckmark />
                      )}
                    </button>
                    <button
                      className="text-xs border-[#6F6F6F] font-medium border text-[#6F6F6F] rounded px-8 py-2 flex gap-3 items-center"
                      onClick={() => handleApproveRequest(2)}
                    >
                      Reject account{" "}
                      {loading?.rejectLoading ? (
                        <LoadingAnimation color={"lightgray"} />
                      ) : (
                        <CrossIcon width={19} height={20} />
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="py-4 px-24">
              {AccountTrack.map((data) => (
                <>
                  <div className="flex items-end gap-7 space-y-3 ml-8">
                    <div>
                      <GroupIcon className={"w-8 h-8"} />
                    </div>
                    <div className="flex flex-col flex-1">
                      <div className="flex font-semibold text-xs italic text-[#BABABA] gap-4 mb-[6px]">
                        <p>Received 1:00pm</p>
                        <span>16th November 2024</span>
                      </div>
                      <div className="bg-[#FAFAFA] text-[#6F6F6F] flex justify-between text-xs font-medium px-5 py-2">
                        <p className="w-1/4">{data.title}</p>
                        <div className="flex w-[40%] gap-2">
                          {data.key === "applied" ? (
                            <span>{data.mode}</span>
                          ) : (
                            <>
                              <span>{data.time}</span>
                              <span>{data.date}</span>
                            </>
                          )}
                        </div>
                        <p className="w-1/4">
                          {data.key === "call" ? data.number : data.position}
                        </p>
                        <p className="w-1/4 text-center">{data.id}</p>
                      </div>
                    </div>
                  </div>
                  <hr className="border-t-1 border-gray-300 my-4" />
                </>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AccountMembersDetail;
