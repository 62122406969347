import { createBrowserRouter, Navigate, redirect } from "react-router-dom";

import LandingPage from "../pages/LandingPage/LandingPage";

import Layout from "../pages/Layout/Layout";
import LoginPage from "../pages/LoginPage/LoginPage";
import RegisterPage from "../pages/RegisterPage/ResgisterPage";
import DashboardPage from "../pages/Dashboard/Dashboard";
import ContactsPage from "../pages/ContactsPage/ContactsPage";
import AddCandidatePage from "../pages/ContactsPage/CandidatesSection/AddCandidatePage/AddCandidatePage";
import EmailVerification from "../pages/EmailVerification/EmailVerification";
import MyAssessmentsPage from "../pages/MyAssessmentsPage/MyAssessmentsPage";
import CalendarsPage from "../pages/CalendarsPage/CalendarsPage";

import CreateAssessment from "../pages/MyAssessmentsPage/CreateAssessmentSection/CreateAssessmentSection";
import MyAssessmentsDetailsPage from "../pages/MyAssessmentsDetailsPage/MyAssessmentsDetailsPage";
import CandidateAssessments from "../pages/MyAssessmentsPage/CandidateAssessments/CandidateAssessments";
import AnswerAssessment from "../pages/MyAssessmentsPage/AnswerAssessment/AnswerAssessment";
import Profile from "../pages/ProfilePage/ProfilePage";
import { useSelector } from "react-redux";
import AddCompanyPage from "../pages/ContactsPage/CompaniesSection/AddCompanyPage/AddCompanyPage";
import { useAxiosInstance } from "../utils/setupInterceptor";
import JobPage from "../pages/JobPage/JobPage";
import CreateJobPage from "../pages/CreateJobPage/CreateJobPage";
import JobDetail from "../pages/JobPage/JobDetail/JobDetail";
import CandidateSection from "../pages/ContactsPage/CandidatesSection/CandidatesSection";
import CompaniesSection from "../pages/ContactsPage/CompaniesSection/CompaniesSection";
import AssessmentListDetails from "../pages/MyAssessmentsPage/AssessmentListDetails/AssessmentListDetails";
import AssessmentView from "../pages/MyAssessmentsPage/AssessmentView/AssessmentView";
import { JobPostSection } from "../pages/JobPage/JobDetail/JobDetailSection/JobPostSection";
import ApplicantSection from "../pages/JobPage/JobDetail/JobDetailSection/ApplicantSection";
import SkillListSection from "../pages/JobPage/JobDetail/JobDetailSection/SkillListSection";
import CreateProfile from "../pages/CreateProfile/CreateProfile";
import AgentProfile from "../pages/ProfilePage/Agent/AgentProfile";
import JobsListPage from "../pages/JobsListPage/JobsListPage";
import JobDetailPage from "../pages/JobDetailPage/JobDetailPage";
import VerifyUserJobPage from "../pages/VerifyUserJobPage/VerifyUserJobPage";
import PublicJobDetailPage from "../pages/PublicJobDetailPage/PublicJobDetailPage";
import JobTrackPage from "../pages/JobTrackPage/JobTrackPage";
import JobTrackDetail from "../pages/JobTrackPage/JobTrackDetail/JobTrackDetail";
import UnauthorizedPage from "../pages/UnauthorizedPage/UnauthorizedPage";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";
import ChangePasswordPage from "../pages/ChangePasswordPage/ChangePasswordPage";
import AccountMembers from "../pages/AccountMembersPage/AccountMembers";
import AccountMembersDetail from "../pages/AccountMembersPage/AccountMembersDetail/AccountMembersDetail";
import ViewAgentProfile from "../pages/ViewAgentProfile/ViewAgentProfile";
import AccountPage from "../pages/AccountPage/AccountPage";
import MyAccountDetailsPage from "../pages/MyAccountDetailsPage/MyAccountDetailsPage";
import MyCompanyDetailsPage from "../pages/MyCompanyDetailsPage/MyCompanyDetailsPage";
import RecruiterCreateProfilePage from "../pages/RecruiterCreateProfilePage/RecruiterCreateProfilePage";
import {
  recruiterApprovalStatus,
  RecruiterTypes,
  userTypes,
} from "../utils/constants";

function useAuthLoader() {
  const auth = useSelector((state) => state.auth);
  const { authenticated, user } = auth;

  const loginLoader = ({ request }) => {
    if (authenticated && user && user._id) {
      return redirect("/");
    }
    return null;
  };

  const authLoader = ({ request }) => {
    if (authenticated && user && user._id) {
      return null;
    }
    return redirect("/login");
  };

  return {
    loginLoader,
    authLoader,
  };
}

function useRoutes() {
  const auth = useSelector((state) => state.auth);

  const { loginLoader, authLoader } = useAuthLoader();
  useAxiosInstance();

  const isLoggedIn = auth.authenticated && auth.user && auth.user._id;
  const isRecruiter = auth?.user?.userType === userTypes.RECRUITER;
  const isRecruiterAdmin =
    isRecruiter && auth?.user?.recruiterType === RecruiterTypes?.ADMIN;

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: isLoggedIn ? (
            isRecruiterAdmin && !auth?.user?.recruiterApprovalStatus ? (
              <Navigate to="/create-profile" />
            ) : !isRecruiter && auth?.user?.enableDefaultProfileOption ? (
              // Candidate
              <Navigate to="/create-profile" />
            ) : (
              <Navigate to="/dashboard" />
            )
          ) : (
            <LandingPage />
          ),
        },
        {
          path: "/login",
          loader: loginLoader,
          element: <LoginPage />,
        },
        {
          path: "/email-verification",
          loader: loginLoader,
          element: <EmailVerification />,
        },
        {
          path: "/register",
          loader: loginLoader,
          element: <RegisterPage />,
        },
        {
          path: "/verify-job/:jobId/:email",
          element: <VerifyUserJobPage />,
        },
        {
          path: "/create-profile",
          loader: authLoader,
          element:
            auth?.user?.userType === 2 ? (
              <RecruiterCreateProfilePage />
            ) : (
              <CreateProfile />
            ),
        },
        {
          path: "/dashboard",
          loader: authLoader,
          element: <DashboardPage />,
        },
        {
          path: "/unauthorized",
          loader: authLoader,
          element: <UnauthorizedPage />,
        },
        {
          path: "/account",
          loader: authLoader,
          children: [
            {
              index: "/",
              element: <AccountPage />,
              children: [
                {
                  path: "/account/account-members",
                  element: <AccountMembers />,
                },
                {
                  path: "/account/my-company-details",
                  element: <MyCompanyDetailsPage />,
                },
                {
                  path: "/account/my-account-details",
                  element: <MyAccountDetailsPage />,
                },
              ],
            },
          ],
        },
        {
          path: "/contacts",
          loader: authLoader,
          children: [
            {
              index: "/",
              element: <ContactsPage />,
              children: [
                {
                  path: "/contacts/candidates",
                  element: <CandidateSection />,
                },
                {
                  path: "/contacts/companies",
                  element: <CompaniesSection />,
                },
              ],
            },
            {
              path: "add-candidate",
              element: <AddCandidatePage />,
            },
            {
              path: "edit-candidate/:candidateId",
              element: <AddCandidatePage />,
            },
            {
              path: "add-company",
              element: <AddCompanyPage />,
            },
            {
              path: "edit-company/:companyId",
              element: <AddCompanyPage />,
            },
          ],
        },
        {
          path: "/my-assessments",
          loader: authLoader,
          children: [
            {
              index: true,
              element: <MyAssessmentsPage />,
            },
            {
              path: "assessment-details/:id",
              element: <MyAssessmentsDetailsPage />,
            },
            {
              path: "create-assessment",
              element: <CreateAssessment />,
            },
            {
              path: "edit-assessment/:assessmentId",
              element: <CreateAssessment />,
            },
            {
              path: "assessment-details",
              element: <AssessmentListDetails />,
            },
            {
              path: "assessment-view/:assessmentId",
              element: <AssessmentView />,
            },
            {
              path: "candidate-Assessments",
              element: <CandidateAssessments />,
            },
            {
              path: "candidate-Assessments/:id",
              element: <AnswerAssessment />,
            },
          ],
        },
        {
          path: "/profile",
          loader: authLoader,
          children: [
            {
              index: true,
              element: <AgentProfile />,
            },
          ],
        },
        {
          path: "/view-profile",
          loader: authLoader,
          children: [
            {
              index: true,
              element: <ViewAgentProfile />,
            },
          ],
        },
        {
          path: "/calendar",
          loader: authLoader,
          children: [
            {
              index: true,
              element: <CalendarsPage />,
            },
          ],
        },
        {
          path: "/jobs",
          children: [
            {
              index: true,
              loader: authLoader,
              element: <JobsListPage />,
            },
            {
              path: "detail/:jobId",
              element: <JobDetailPage />,
            },
          ],
        },
        {
          path: "job-detail/:jobId",
          element: <PublicJobDetailPage />,
        },
        {
          path: "/job",
          loader: authLoader,
          children: [
            {
              index: true,
              element: (
                <ProtectedRoute allowedRoles={[2]} user={auth?.user}>
                  <JobPage />
                </ProtectedRoute>
              ),
            },
            {
              path: "create",
              loader: authLoader,
              element: <CreateJobPage />,
            },
            {
              path: ":jobId",
              loader: authLoader,
              element: (
                <ProtectedRoute allowedRoles={[2]} user={auth?.user}>
                  <JobDetail />
                </ProtectedRoute>
              ),
              children: [
                {
                  path: "job-post",
                  element: <JobPostSection />,
                },
                {
                  path: "skills",
                  element: <SkillListSection />,
                },
                {
                  path: "applicants",
                  element: <ApplicantSection />,
                },
              ],
            },
          ],
        },
        {
          path: "/account",
          loader: authLoader,
          element: <AccountPage />,
        },
        {
          loader: authLoader,
          path: "/account/account-members/:id",
          element: <AccountMembersDetail />,
        },
        {
          path: "/my-profile",
          loader: authLoader,
          element: <Profile />,
        },
        {
          path: "/change-password",
          loader: authLoader,
          element: <ChangePasswordPage />,
        },
        {
          path: "/job-track",
          loader: authLoader,
          element: <JobTrackPage />,
        },
        {
          path: "/job-track-detail",
          loader: authLoader,
          element: <JobTrackDetail />,
        },
        {
          path: "*",
          loader: authLoader,
          element: <DashboardPage />,
        },
      ],
    },
  ]);

  return {
    router,
  };
}

export default useRoutes;
